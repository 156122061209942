span.title {
  margin: 10px;
  padding: 10px;
}

span.logo {
  margin: 5px;
  margin-left: 10px;
}
header {
  background-color: #282c34;
  min-height: 5vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

header div {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}

header div span {
  color: white;
  cursor: pointer;
}

header div span.selected {
  cursor: auto;
  color: #61dafb;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
